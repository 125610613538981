// Entry point for the standalone creative hub used for local development
import * as ReactDOM from "react-dom/client";
import { createBrowserRouterWrapper } from "./router";
import { App } from "./App";
import { readFromSessionStorage } from "./UserProvider/models/User";
import env from "./UserProvider/utils/env";

const domNode = document.getElementById("root");
if (domNode == null) {
  throw new Error(
    "Tried to mount react element but not element with id '#root' exists"
  );
}

const router = createBrowserRouterWrapper();

const root = ReactDOM.createRoot(domNode);
let user = null;
if (env.enableLocalUserControl) {
  user = readFromSessionStorage();
}

root.render(<App router={router} user={user} />);
